<template>
	<div>
		<div v-if="emailSent" class="text-center">
			<div class="alert alert-info mb-4">
				{{ $t('forgottenPassword.email-was-sent') }}
			</div>
		</div>
		<div v-if="!token && !emailSent">
			<div class="alert alert-info mb-4">
				{{ $t('forgottenPassword.byEmailHeader') }}
			</div>
			<div>
				<div class="form-group">
					<label for="email">{{ $t('forgottenPassword.email') }}:</label>
					<EmailInput
						id="email"
						v-model="email"
						:msgs="msgs"
						class="form-control"
						maxlength="255"
						:required="true"
					/>
					<ComponentMessage :msgs="msgs" forComponent="email"/>
				</div>

				<div class="form-group text-center">
					<button class="btn btn-primary" @click="forgottenPasswordEmail">
						<font-awesome-icon icon="user"/>
						{{ $t('forgottenPassword.requestNewPassword') }}
					</button>
				</div>

			</div>
		</div>

		<div class="card" v-if="token">
			<div class="card-header">
				{{ $t('forgottenPassword.changePassword') }}
			</div>
			<div class="card-body">
				<form>

					<div class="row">
						<div class="col-sm-6">
							<div class="form-group">
								<label for="password">{{ $t('forgottenPassword.password') }}:</label>
								<PasswordInput
									id="password"
									v-model="password"
									:msgs="msgs"
									class="form-control"
									maxlength="255"
								/>
								<ComponentMessage :msgs="msgs" forComponent="password"/>
							</div>
						</div>

						<div class="col-sm-6">
							<div class="form-group">
								<label for="password2">{{ $t('forgottenPassword.password2') }}:</label>
								<PasswordInput
									id="password2"
									v-model="password2"
									:msgs="msgs"
									class="form-control"
									maxlength="255"
									:password1="password"
								/>
								<ComponentMessage :msgs="msgs" forComponent="password2"/>
							</div>
						</div>
					</div>

					<div class="form-group text-center">
						<button class="btn btn-primary" @click="saveUser" type="button">
							<font-awesome-icon icon="check"/>
							{{ $t('forgottenPassword.save') }}
						</button>
					</div>

				</form>
			</div>
		</div>

	</div>
</template>

<script>
export default {
	name: 'ForgottenPasswordView',
	data() {
		return {
			emailSent: false,
			email: '',
			password: '',
			password2: '',
			token: null
		};
	},
	props: {
		msgs: Object
	},
	methods: {
		async forgottenPasswordEmail() {
			this.emailSent = await this.$rest.forgottenPasswordEmail(this.email);
		},
		async saveUser() {
			const success = await this.$rest.forgottenPasswordChange(this.password, this.password2,this.token)
			if(success){
				await this.$router.push("/login")
			}
		},
		parseToken() {
			const token = this.$route.query.token
			if(token) {
				this.token = token
			}
		}
	},
	beforeMount() {
		this.parseToken()
	}
}
</script>
